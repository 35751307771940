
// ------------------------------------------------------------------ bootstrap

// Core variables and mixins
@import "../../bower_components/bootstrap/less/variables.less";
@import "../../bower_components/bootstrap/less/mixins.less";

// variable overrides
@thumbnail-padding: 4px;
@thumbnail-caption-padding: 4px;

// Reset
@import "../../bower_components/bootstrap/less/normalize.less";
@import "../../bower_components/bootstrap/less/print.less";

// Core CSS
@import "../../bower_components/bootstrap/less/scaffolding.less";
@import "../../bower_components/bootstrap/less/type.less";
@import "../../bower_components/bootstrap/less/code.less";
@import "../../bower_components/bootstrap/less/grid.less";
@import "../../bower_components/bootstrap/less/tables.less";
@import "../../bower_components/bootstrap/less/forms.less";
@import "../../bower_components/bootstrap/less/buttons.less";

// Components
@import "../../bower_components/bootstrap/less/component-animations.less";
@import "../../bower_components/bootstrap/less/glyphicons.less";
@import "../../bower_components/bootstrap/less/dropdowns.less";
@import "../../bower_components/bootstrap/less/button-groups.less";
@import "../../bower_components/bootstrap/less/input-groups.less";
@import "../../bower_components/bootstrap/less/navs.less";
@import "../../bower_components/bootstrap/less/navbar.less";
@import "../../bower_components/bootstrap/less/breadcrumbs.less";
@import "../../bower_components/bootstrap/less/pagination.less";
@import "../../bower_components/bootstrap/less/pager.less";
@import "../../bower_components/bootstrap/less/labels.less";
@import "../../bower_components/bootstrap/less/badges.less";
@import "../../bower_components/bootstrap/less/jumbotron.less";
@import "../../bower_components/bootstrap/less/thumbnails.less";
@import "../../bower_components/bootstrap/less/alerts.less";
@import "../../bower_components/bootstrap/less/progress-bars.less";
@import "../../bower_components/bootstrap/less/media.less";
@import "../../bower_components/bootstrap/less/list-group.less";
@import "../../bower_components/bootstrap/less/panels.less";
@import "../../bower_components/bootstrap/less/wells.less";
@import "../../bower_components/bootstrap/less/close.less";

// Components w/ JavaScript
@import "../../bower_components/bootstrap/less/modals.less";
@import "../../bower_components/bootstrap/less/tooltip.less";
@import "../../bower_components/bootstrap/less/popovers.less";
@import "../../bower_components/bootstrap/less/carousel.less";

// Utility classes
@import "../../bower_components/bootstrap/less/utilities.less";
@import "../../bower_components/bootstrap/less/responsive-utilities.less";

// --------------------------------------------------------------- font-awesome

@import "../../bower_components/font-awesome/less/variables.less";
@fa-font-path: "/static/font-awesome/fonts";

@import "../../bower_components/font-awesome/less/mixins";
@import "../../bower_components/font-awesome/less/path";
@import "../../bower_components/font-awesome/less/core";
@import "../../bower_components/font-awesome/less/larger";
@import "../../bower_components/font-awesome/less/fixed-width";
@import "../../bower_components/font-awesome/less/list";
@import "../../bower_components/font-awesome/less/bordered-pulled";
@import "../../bower_components/font-awesome/less/animated";
@import "../../bower_components/font-awesome/less/rotated-flipped";
@import "../../bower_components/font-awesome/less/stacked";
@import "../../bower_components/font-awesome/less/icons";

// ----------------------------------------------------------------- selectize
@import "../../bower_components/selectize/dist/less/selectize.bootstrap3.less";

// ----------------------------------------------------------------- thumbnails

.thumbnail {
    > button {
        .pull-right;
        margin: @thumbnail-caption-padding 0 0 0;
    }
    > i.fa.fa-fw {
        display: block;
        margin: 10px auto;
    }
}

// ----------------------------------------------------------------- pikaday

@import (inline) "../../bower_components/pikaday/css/pikaday.css";

// ------------------------------------------------------------------ typeahead

// bootstrap 2.0 -> 3.0 layer
@white: #fff;
@orange: @btn-warning-bg;
@grayLight: @gray-light;
@grayLighter: @gray-lighter;
.buttonBackground(@color; @background;) {
    .button-variant(@color; @background; @btn-default-border);
}
@btnPrimaryBackground: @btn-primary-bg;
@baseLineHeight: @line-height-base;
.border-radius(@radius) {
    border-radius: @radius;
}
@dropdownBackground: @dropdown-bg;
@dropdownBorder: @dropdown-border;
@dropdownLinkBackgroundHover: @dropdown-link-hover-bg;
@dropdownLinkColorHover: @dropdown-link-hover-color;
@import "../../bower_components/typeahead.js-bootstrap/typeahead.js-bootstrap.less";

.form-control.user-picker {

    ~ i {
        display: none;
    }

    &.disabled-user {
        text-decoration: line-through;
    }

    &.has-user {
        padding-right: 1.5 * @font-size-base;

        // center vertically (have to force a fixed font size) and align right
        + i {
            position: absolute;
            display: block;
            top: 50%;
            right: 0.5 * @font-size-base;
            margin-top: -0.5 * @font-size-base;
            font-size: @font-size-base;
        }
    }
}

.twitter-typeahead {
    width: 100%;
}

// ------------------------------------------------------------- navbar tweaks

// in short: remove border radius, allow non-collapsing "2nd level" navbar
// right below the primary one

.navbar {

    border-radius: 0;
    border-left: none;
    border-right: none;

    &.navbar-secondary {

        border-top: none;
        margin-top: -1 * @navbar-margin-bottom;

        background-color: @navbar-default-border;
        border-bottom-color: darken(@navbar-default-border, 10%);

        .navbar-nav {

            > .active > a {
                border-bottom: 4px solid darken(@navbar-default-border, 25%);
            }

            > li > a {
                &:hover,
                &:focus {
                    border-bottom: 4px solid darken(@navbar-default-border, 40%);
                }
            }

            // the following is from the non-collapsed navbar stuff, basically
            // we are disabling responsive collapsing for this navbar
            float: left;
            margin: 0;
            > li {
              float: left;
              > a {
                border-bottom: 4px solid @navbar-default-border;
                padding-top: ((@navbar-height - @line-height-computed) / 2);
                padding-bottom: ((@navbar-height - @line-height-computed) / 2);
              }
            }
            // end
        }
    }
}

// ------------------------------------------------------------- badge variants

.badge {
    &.badge-important { background-color: @brand-danger; }
    &.badge-info { background-color: @brand-info; }
}

// ----------------------------------------- ember fixes for various list types

.pagination > li {
    &:first-of-type {
        > a, > span {
            margin-left: 0;
            .border-left-radius(@border-radius-base);
        }
    }
    &:last-of-type {
        > a, > span {
            .border-right-radius(@border-radius-base);
        }
    }
}

.btn-group > .btn {
    &:first-of-type {
        border-top-left-radius: @border-radius-base !important;
        border-bottom-left-radius: @border-radius-base !important;
    }
    &:last-of-type {
        border-top-right-radius: @border-radius-base !important;
        border-bottom-right-radius: @border-radius-base !important;
    }
}

.list-group > li {
    &:first-of-type {
        .border-top-radius(@list-group-border-radius);
    }
    &:last-of-type {
        .border-bottom-radius(@list-group-border-radius);
    }
}

.breadcrumb {
    > li {
        &:before {
            content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
            padding: 0 5px;
            color: @breadcrumb-color;
        }
        &:first-of-type:before {
            content: '';
        }
    }
}

// ------------------------------------------------------------------ map views

.btn-geolocation {
    &.loading {
        &:after {
            margin-left: 10px;
            .fa();
            .fa-spin();
            content: @fa-var-spinner;
        }
    }
}

.map {
    margin-top: 10px;
    width: 200px;
    height: 200px;

    &:before {
        .fa();
        content: @fa-var-globe;
        line-height: 160px;
        .well();
        display: block;
        text-align: center;
        height: 200px;
        font-size: 80px;
        color: @gray;
    }

    &.nomap > * {
        visibility: hidden;
    }

    &.map-large {
        width: inherit;
        height: 500px;

        &:before {
            height: 500px;
            line-height: 400px;
            font-size: 160px;
        }
    }
}

// ---------------------------------------------------------------- nav-list

// nav-list was removed in bootstrap 3, lets bring it back
.nav-list {
  padding-left: 15px;
  margin-bottom: 0;
}

.nav-list > li > a,
.nav-list .nav-header {
  margin-left: -15px;
  margin-right: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
}

.nav-list > li > a {
  padding: 3px 15px;
}

.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
  background-color: #08c;
}

.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  margin-right: 2px;
}

.nav-list .divider {
  *width: 100%;
  height: 1px;
  margin: ((1.428571429 / 2) - 1) 1px; // 8px 1px
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #428bca;
  border-bottom: 1px solid white;
}

.nav-header {
  display: block;
  padding: 3px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.428571429;
  color: #999;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  text-transform: uppercase;
}
// Space them out when they follow another list item (link)
.nav li + .nav-header {
  margin-top: 9px;
}


// ---------------------------------------------------------------- app loading

// include some static markup in index.html pages that will dissappear once the
// ember app loads.. aka a loading screen
.ember-application .pre-ember {
    display: none;
}

#application-loading {

    width: 300px;
    padding: 10px;
    margin: 30% auto 0 auto;

    h1 {
        padding-left: 98px;
        background: url('/client/img/android-icon-196.png') center left no-repeat;
        background-size: 98px 98px;
        line-height: 98px;
        font-size: 2.25em;
    }
}

// ----------------------------------------------------------------------- misc

.address input.form-control {
    display: inline;
    margin-bottom: 5px;
    margin-right: 3px;
    &.input-city {
        width: 50%;
    }
    &.input-state {
        width: 17%;
    }
    &.input-postal {
        width: 26%;
    }
}

// https://github.com/emberjs/ember.js/issues/605#issuecomment-25082858
[data-ember-action] {
  cursor: pointer;
}

// ----------------------------------------------------------------------- misc

// make btn-group focus more clearly visible (removes confusion in handiforms
// when input focus is put on a yes/no button after validation fails #103)
.btn-group > .btn:focus {
    .tab-focus();
}

// remove vertical margins from paginator
.pagination {
    margin-top: 0;

    & +.stats {
        padding: @padding-base-vertical @padding-base-horizontal;
        line-height: @line-height-base;
    }
}

.datepicker.datepicker-dropdown {
    z-index: 9999 !important;
}

table.table {
    tr.filters {
        input[type="text"].form-control {
            min-width: 75px;
            max-width: 200px;

            &.xs {
                min-width: 30px;
                max-width: 100px;
            }
        }
    }

    &.table-sm {
        tbody > tr > td {
            font-size: 12px;
        }
    }

    &.table-hover {
        tbody > tr > td {
            cursor: pointer;
        }
    }
}

.modal.modal-wide {
    .modal-dialog {
        @media screen and (min-width: 768px) {
            width: 760px;
        }
    }
}

.modal.modal-narrow {
    .modal-dialog {
        @media screen and (min-width: 768px) {
            width: 400px;
        }
    }
}

.form-dense {
    font-size: 12px;
    .control-label,
    .form-control,
    .checkbox {
        font-size: inherit;
    }
    legend {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
    }
    .form-group {
        margin-bottom: 7px;
    }
}

.form-signin {
    input[type="text"] {
        margin-bottom: -1px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

#loading {
    position: fixed;
    top: 40%;
    left: 40%;
    right: 40%;
    max-width: 300px;
    min-width: 150px;
    background-color: #eee;
    padding: 10px;
    border-radius: 10px;

    > .well {
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

// task state labels
.label.label-task-state {
    &.Pending { .label-info }
    &.Open { .label-primary }
    &.Closed { .label-success }
    &.Deleted { .label-default }
}

// task priority labels
.label.label-task-priority {
    &.Normal { .label-default }
    &.High { .label-warning }
    &.Immediate { .label-danger }
}

// handiforms state labels
.label.label-form-state {
    &.Deleted { .label-default }
    &.Canceled { .label-default }
    &.Started { .label-success }
    &.Finished { .label-primary }
}

.page-header {
    margin: 0px 0 5px;
    > h1 {
        margin: 0;
        font-size: @font-size-h3;
    }
    @media (min-width: @grid-float-breakpoint) {
        margin: 30px 0 15px;
        > h1 {
            font-size: @font-size-h1
        }
    }
}

.help-inline {
    &:before { content: ' - '; }
    color: lighten(@text-color, 25%);
}

.handiform-elements > .list-group-item.active,
.handiform-elements > .list-group-item.active:hover, {
  border-width: 2px;
  border-color: #ccc;
  color: inherit;
  z-index: inherit;
  background-color: #fafafa;
}

.inline-rule {
    line-height: @input-height-small;
    > span { margin-bottom: 0.25em; }
    .text { padding: 0 .5em; }
}

.chart {
    width: 600px;
    height: 375px;
}

.choices > li {
    margin-bottom: 3px;
}

.handiforms-column {
    -webkit-column-width: 300px;
    -moz-column-width: 300px;
    column-width: 300px;
    column-fill: balance;

    > .panel {
        -webkit-column-break-inside: avoid;
        display: inline-block;
        width: 100%;
    }
}

.customize-column {
    -webkit-column-width: 200px;
    -moz-column-width: 200px;
    column-width: 200px;
    column-fill: balance;

    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        padding: 5px 0 5px 0;
        > .checkbox { margin: 0; }
    }
}
