@import "../shared/styles.less";

// --------------------------------------------------------------------calendar

@import (less) "../../bower_components/bootstrap-calendar/css/calendar.css";

#cal-slide-content {
    background: #444;
    &:hover {
        background: #666;
    }
}

.glyphicon { .fa }
.glyphicon-chevron-down:before {
    content: @fa-var-chevron-down;
}

// --------------------------------------------------------------------- client

#ttp-client {


}

// resolve issue where unable to tell when disabled yes/no buttons are active on handiforms
.btn-default[disabled].active {
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
    color: @btn-default-color;
    background-color: darken(@btn-default-bg, 8%);
}


.handiforms-form {
    max-width: 600px;
    margin: 0 auto 80px auto;

}

.handiforms-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    background: @panel-footer-bg;
    padding: 10px;
    z-index: 100;

    > .container {
        padding: 0;
        max-width: 600px;
    }
}

.handiforms-builder {
    margin-bottom: 80px;
    .handiforms-footer {
        > .container {
            max-width: inherit;
        }
    }
}
